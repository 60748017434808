@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$breakheight: 1200px;
$transition-speed: 0.3s;

#workout-show {

  .start-button {
    display: block;
    width: 100%;
    font-size: 3rem;

    // text-align: center;
    // color: white;
    // background-color: $success;
    // border: 2px solid darken($success, 50%);
    // box-shadow: 0px 0px 5px 2px lighten($success, 10%);
  }
}
