// @import "../../node_modules/bootstrap/scss/functions";
// @import "../../node_modules/bootstrap/scss/variables";
// @import '../../node_modules/bootstrap/scss/mixins';
// @import '../../node_modules/bootstrap/scss/utilities';

@import 'variables';

.drill-edit {
  .seq-form {
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .reps {
    input {
      width: 4.2rem;
    }
  }

  .bi-node-plus-fill, .bi-trash {
    cursor: pointer;
    color: $gray-600;
    &:hover {
      color: black;
    }
  }

  .prompt-dropdown-info {
    min-width: 300px;
    max-width: 80vh;
    .dropdown-item {
      white-space: normal;
    }
  }


  // .form-control::-webkit-inner-spin-button {
  //   opacity: .3;
  // }
  .was-validated {
    .form-control:valid, .form-control:invalid {
      // background-size: calc((0.75em + 0.375rem)/2);

      // webkit-inner-spin-button is the up/down arrows on the input, shown on chrome, took too much space by default
      // when shown with validation ticks, so show it at the same spot always
      &::-webkit-inner-spin-button {
        // opacity: 1;
        margin-right: calc(-0.75em - 0.75em);
      }
    }
  }

  #buttons {
    .btn {
      // @include utilities.mb-2;
      margin-bottom: $spacer * .5;
      // background-color: red !important;
      margin-right: $spacer * .5;

      &:last-child { margin-right: 0; }
    }
  }
}

