@import 'variables';

.layout-container {
  margin-top: 1rem;
  margin-bottom: 5rem;
}

// #loading-spinner {
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   margin-top: -5rem;
//   margin-left: -5rem;
//   z-index: 9999;
//   span {
//     width: 10rem;
//     height: 10rem;
//     border-width: 20px;
//   }
// }

.navbar-footer {
  .navbar-brand {
    // color: $brand-color;
    font-weight: 500;
  }

  ul.navbar-nav {

    .nav-link { // funky way to ensure that the nav-link is centered vertically
      padding-right: 1rem; // make all links same width apart (from brand)
      padding-bottom: calc(0.5rem - 2px);


      // padding-bottom: 2px;
      // @include media-breakpoint-up(md) {
      //   padding-bottom: 4px;
      // }
    }

    .volume {
      font-size: 140%;
    }
  }
}

// Funky way to force there always to be space after icons, because sometimes react will remove those
// i.bi::after, .spinner-border::after {
.micon {
  margin-right: 0.25*$spacer;
  &::after {
    content: ' ';
  }
}
.spinner-border.micon {
  margin-right: 0.5*$spacer;
}

.common-page {
  .title, h1, h2, p {
    max-width: $content_max_width;
  }


  .title {
    margin-bottom: 0.5*$spacer;

    background-color: $brand-color;
    color: white;
    border-radius: var(--bs-border-radius);
    overflow: hidden;
    display: flex;
    align-items: center; /* Center-align items vertically */

    // flex-direction: column; /* Stack items vertically */

    // justify-content: center; /* Center content horizontally */
    // text-align: center;

    img {
      height: calc(4rem + 8px);
      float: left;
      margin-right: 1em;
      border-radius: var(--bs-border-radius);
    }

    h1, h2 {
      margin: 0;
      padding: 4px;
      padding-left: 0px;
    }

    &::after {
      content: ''; display: block; clear: both;
    }

    @media (min-height: 1400px) {
      margin-bottom: 1*$spacer;
      img { height: 6rem; }
    }
  }

  .title-xl {
    margin-bottom: 2*$spacer;

    img {
      height: 8rem;
    }
    // h1, h2 {
    //   min-height: 8rem;
    // }
  }
}

.seo-page {
  .section {
    margin-bottom: 3*$spacer;
  }
  // .sub-section {
  //   margin-bottom: 2*$spacer;
  // }
  // p {
  //   margin-top: 2*$spacer;
  // }
  // p+h2 {
  //   margin-top: 2*$spacer;
  //   color: red !important;
  // }
  h2 {
    margin-bottom: 1*$spacer;
  }
  // p { margin-bottom: 3*$spacer; }
  // p+p { margin-top: -2*$spacer; }

  iframe.youtube {
    width: 100%;
    min-height: 300px;
  }

  iframe.balance-demo {
    width: 100%;
    aspect-ratio: 1.0;
    max-height: 400px;
    max-width: 400px;
  }

  .drill-btn {
    padding-left: 0.25rem; // default 0.75
    img {
      height: 2em;
      margin-right: 0.25rem;
    }
  }
}

#MainPage {
  .first-time-intro {
    p {
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
  }
}

// #AppAdvert {
//   // margin: -25px;
//   img {
//     // margin: -50px;
//     // width: 50%;
//     // margin-left: -50px;
//   }
// }

.search {
  max-width: $content_max_width;

  .results {
    min-height: 60vh;
  }
}

.drill-list {
  .item {
    max-width: $content_max_width;
    position: relative;
    // border: 1px solid var(--bs-gray-300);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
    border-radius: var(--bs-border-radius);
    // padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    margin-bottom: $spacer * 0.5;

    a:first-child { // everything but the edit-link
      display: inline-block;
      padding: 0.5rem;
      padding-right: 1.5rem;
      color: var(--bs-body-color);
      text-decoration: none;
      width: 100%;

      &:hover {
        background-color: var(--bs-list-group-action-hover-bg);
      }
      h5 {
        // color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity,1))
        color: $brand-color;
      }
      // > span {
      // }
    }

    .edit-link {
      position: absolute;
      right: 0; top: 0;
      z-index: 1000;
      // padding: 10px;
      // padding-right: 0px;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      height: 100%;
      &:hover {
        background-color: var(--bs-list-group-action-hover-bg);
      }
      i {
        display: block;
        position: relative;
        top: 50%; transform: translateY(-50%);
      }
    }
  }

}
