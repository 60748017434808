
// Brand color change, search for previous, might also to package.json
$brand-color: #1144bb;
// $brand-color: #2255bb;
// $brand-color: #00ff00;
// $primary-color: #ff0000;
$blue: $brand-color;
$primary: $brand-color;
$link-shade-percentage: 30%;

$content_max_width: 720px; // used for items and some containers, not for all because we want to have forms full-width etc

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables"; // includes colors etc
@import '../../node_modules/bootstrap/scss/mixins';

// Import Bootstrap's source SCSS
// @import "../../node_modules/bootstrap/scss/bootstrap";


