@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$breakheight: 1200px;
$transition-speed: 0.3s;
$more-info-arrow-size: 4vh;

@keyframes flash-page-anim-keys {
  0% { opacity: 1; }
  // 50% { opacity: var(--bs-info); }
  30% { opacity: 0.8; }
  100% { opacity: 1; }
}
@keyframes flash-top-page-anim-keys {
  0% { background-color: inherit; }
  20% { background-color: inherit; }
  // 20% { background-color: var(--bs-info); }
  60% { background-color: var(--bs-primary-bg-subtle); }
  // 80% { background-color: var(--bs-info); }
  90% { background-color: inherit; }
}

#drill-show-outer {
  // @media (min-height: 1000px) {
  //   // grid-template-rows: auto 1fr 1fr;
  //   margin-bottom: 6rem;
  //   .navbar-footer {
  //     position: fixed; right: 0; bottom: 0; left: 0;z-index: 1030;
  //   }
  // }

  // Force footbar at bottom when content under screen height.
  min-height: 100vh;
  display: flex; flex-direction: column; justify-content: space-between;

  .bottom-container {
    flex: 1;

    iframe { // youtube
      width: 100%;
      aspect-ratio: 16 / 9;
      overflow: hidden;
      // margin: 1.5rem 0;
      margin: 0;
      // margin-bottom: 1rem;
    }
  }

  .workout-drill-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;

    a {
      text-decoration: none;
      display: grid;
      column-gap: 0.3rem;
      i { transform: translateY(1px); }
      span {
        white-space: nowrap; overflow: hidden;
      }
      &:first-child {
        span { text-overflow: ellipsis; }
        grid-template-columns: auto 1fr;
      }
      &:last-child {
        grid-template-columns: 1fr auto;
        text-align: right;
      }
    }
  }

}

#drill {
  display: grid;
  // grid-template-rows: 100vh auto auto;
  // grid-template-rows: 100dvh auto auto;
  grid-template-rows: calc(100vh - $more-info-arrow-size) $more-info-arrow-size;
  grid-template-rows: calc(100svh - $more-info-arrow-size) $more-info-arrow-size;
  // min-height: 100vh;
  transition: $transition-speed;
  margin-bottom: 0.5*$spacer;

  // Animation to flash when switching between drills in a workout.
  > div { background-color: white; }
  &.flash {
    > div { animation: flash-page-anim-keys 0.5s ease-in-out; }
    background-color: var(--bs-primary-bg-subtle);
    .top {
      animation: flash-top-page-anim-keys 1s ease-in-out;
    }
  }
  // &.solid { // dvh unit causes changes, we want to show them immidiately if possible.
  //   transition: none;
  // }

  .header {
    // margin-bottom: 0.5*$spacer;
    margin-bottom: 0.25*$spacer;
  }

  .timer {
    display: grid;
    width: 100%;
    overflow-x: hidden;
    grid-template-rows: auto 1fr auto;
    // grid-template-rows: auto 1fr auto $more-info-arrow-size;
    grid-template-columns: 100%;

    // @media (min-height: $breakheight) {
    //   // grid-template-rows: auto 1fr 1fr;
    //   grid-template-rows: auto calc($breakheight - 300px) 1fr;
    // }
  }

  .info-arrow {
    // position: absolute;
    height: $more-info-arrow-size;
    width: 100%;
    text-align: center;
    font-size: $more-info-arrow-size;
    // color: $gray-900;
    color: $gray-900;
    overflow: hidden;

    // background-image: linear-gradient($gray-600, $gray-100);

    transition: $transition-speed;

    i {
      position: relative;
      top: -0.5vh;

      // Test if you want to see arrow fading a bit.
      // top: -3vh;
      // font-size: 6vh;
      // color: white;
      // transform: scaleX(200%);
      // background-image: linear-gradient(black, black 20%, white);

    }

    // &:parent
  }

  &.running {
    .header h2 {
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 10hh;
      overflow: hidden;
    }
  }

  &.init-info {
    // grid-template-rows: 75vh;
    grid-template-rows: 75vh 0;
    // margin-bottom: 1.5rem;
    margin-bottom: 1.5*$spacer;

    // .timer {
    //   grid-template-rows: auto 1fr auto 0;
    // }

    .info-arrow {
      height: 0;
    }
  }
  &.workout-drill.init-info {
    // grid-template-rows: 85vh auto;
    grid-template-rows: 85vh 0;
  }
  .flash-element {
    // position: absolute; top: 0; left: 0; width: 100%; height: 100%;
    // background-color: rgba(255,255,255,0.5);
    // z-index: 1000;
    // animation: flash 0.5s ease-in-out;

    // animation: flash 0.5s ease-in-out;
    // animation: flash 0.5s ease-in-out;
  }

  .prompt-container {
    // border: 2px solid blue; z-index: 1000;
    position: relative;
    padding-top: 2vh;
    overflow: hidden;

    // border-radius: var(--bs-border-radius);
    border-top-left-radius: var(--bs-border-radius); border-top-right-radius: var(--bs-border-radius);

    margin-top: 4px;
    // margin-bottom: 2px;

    .not-running-play-button {
      position: absolute; top:0; left: 0; width: 100%; height: 100%;
      background-color: rgba(0,0,0,0.5);
      // background: repeating-linear-gradient( -45deg, gray, gray 5px, #e5e5f7 5px, #e5e5f7 25px );

      button {
        position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
        width: 50%; max-width: 340px; aspect-ratio: 2/1;
        opacity: 0.85;
        font-size: 10vh;
        border: 1px solid darken($success, 10%);
        background-color: mix(white, $success, 10%);
        box-shadow: 0px 0px 5px 10px #ffffff66;
        &:hover {
          background-color: mix(white, $success, 20%);
          box-shadow: 0px 0px 5px 10px #ffffff99;
        }
      }
    }
  }

  .prompt {
    height: 100%; width: 100%;
    color: white;
    text-align: center;
    // display: grid;

    .text {
      > span { display: inline-block; }
    }
  }

  .prompt-timed {
    // border: 2px solid pink; z-index: 1001;
    // display: grid;
    // grid-template-rows: 1fr 1fr;
    font-size: 15vh;

    > div {
      position: relative;
      width: 100%;
      overflow: hidden;
      display: grid;
    }

    > div:only-child { // only clock
      height: 100%;
    }

    > div:not(:only-child) { // info and clock
      height: 50%;
      // min-height: 25%;

      // &:first-child {
      //   min-height: 50%;
      //   max-height: 60%;
      // }

      // &:last-child {
      //   min-height: 40%;
      // }
    }

    > div > div {
      align-self: center;
      justify-self: center;
    }

    .text {
      &.length_3 { font-size: 160%; }
      &.length_long { font-size: min(80%, 4rem); }
      &.length_long_paragraph { font-size: min(50%, 3rem); }

      @media (max-width: 1000px) {
        &.length_9 { font-size: 80%; }
        &.length_long { font-size: 60%; }
      }

      @media (max-width: 700px) {
        &.length_6 { font-size: 80%; }
        &.length_9 { font-size: 60%; }
        &.length_long { font-size: 40%; }
      }

      &.strong { // Used for highlighting a word in a paragraph.
        font-style: italic;
        font-weight: bolder;
      }
    }

    @media (min-height: $breakheight) {
      font-size: 130px;
    }
  }

  .prompt-static {
    font-size: 5vh;
    // padding-top: 15vh;
    // font-size: 20px;

    display: grid;
    grid-template-rows: 1fr auto;

    > div:first-child {
      position: relative;
      > div {
        width: 100%;
        position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
        overflow: hidden; text-overflow: ellipsis;
      }
    }

    // grid-template-columns: 100%;

    @keyframes growshrink {
      from { transform: scale(0.3); opacity: 0.3; }
      50% { transform: scale(1); opacity: 1; }
      to { transform: scale(0.3); opacity: 0.3; }
      // to { transform: scale(1); opacity: 1.0; }
    }

    .action {
      width: 1em; height: 1em; margin-right: 0.5em;
      vertical-align: -0.15em;

      // --bs-spinner-animation-speed: 0.75s; animation-direction: alternate;
      --bs-spinner-animation-speed: 1.5s;
      opacity: 1;
      animation-name: growshrink;
      // animation: var(--bs-spinner-animation-speed) linear infinite growshrink;
    }

    .next-step {
      font-size: 80%;
      padding: 5vh;
      text-align: right;
      button {
        // display: inline-block; position: absolute; right: 5vw; bottom: 15%;
        font-size: inherit;
        background-color: $gray-600;
        border-color: $gray-900;
        color: white;
        // height: 20%;
        min-width: 50vw; max-width: 80vw;
        white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
      }
      i {
        margin-right: 0.5em;
        font-size: 90%; // moves it down a bit
      }
    }
  }

  // .navbar-footer {
  //   --bs-navbar-padding-y: 0px !important;
  // }

  /* .drill .btn-group .btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) { */
  .btn-group .btn {
    // border-radius: 10px !important;
    font-size: 2rem;
    // border-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    width: 25%;

    &.only-stop {
      width: 50%;
    }

    &:disabled {
      // color: $gray-900;
      // color: color-mix(in srgb, currentColor 40%, transparent);
      color: rgba(0,0,0,0.2);
      // opacity: 1;
      background-color: $gray-600; border-color: $gray-600;
    }
  }
  // .drill .btn-group .btn:not(:last-child) {
  //   margin-right: 10px !important;
  // }

  .top {
    padding-top: 1rem;
    // animation: top-flash-anim-keys 1s ease-in-out;
    // transition: background-color 1s ease-in-out; // Animation to highlight the change
  }

  // @keyframes top-flash-anim-keys {
  //   0% { background-color: inherit; }
  //   50% { background-color: var(--bs-info); }
  //   100% { background-color: inherit; }
  // }
  // .top-flash {
  //   animation: top-flash-anim-keys 1s ease-in-out;
  // }

  .progress-bars {
    // border: 1px solid gray;
    border: 1px solid rgb(146, 145, 145);
    border-radius: var(--bs-border-radius);

    // background-color: white;
    // background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%, black 25%, black 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,black 75%,black)

    > div {
      // background-image:
      // background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
      // background-color: rgb(199, 199, 199);
      // background-color: #ccc;
      // background-image: linear-gradient(45deg,rgba(255,255,255,.30) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.30) 50%,rgba(255,255,255,.30) 75%,transparent 75%,transparent);

      background-image: linear-gradient(45deg,rgba(0,0,0,.05) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.05) 50%,rgba(0,0,0,.05) 75%,transparent 75%,transparent);
      background-size: var(--bs-progress-height) var(--bs-progress-height);
      > div {
        // background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
        background-size: var(--bs-progress-height) var(--bs-progress-height);
        background-image: linear-gradient(45deg,rgba(255,255,255,.05) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.05) 50%,rgba(255,255,255,.05) 75%,transparent 75%,transparent);
        border-top-right-radius: var(--bs-border-radius);
        border-bottom-right-radius: var(--bs-border-radius);
      }
    }
    > div:first-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: -1px;
    }
    > div:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .progress-bar-steps .progress-bar {
    transition: none !important; // So that when we reset the bar it goes straight to zero
  }

  .progress-bar-running .progress-bar {
    transition-duration: .1s; // Should be a tiny bit smaller as in Drill.js timerStep
    transition-timing-function: linear;
  }



}

